<template>
  <v-dialog transition="slide-y-transition" v-model="dialog" max-width="650">
    <v-card>
      <v-card-title class="grey lighten-4">
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ date | formattedDate }} - {{ dataType && $t(`dashboard.data_type_enum.${dataType}`) }}
          </v-list-item-title>
        </v-list-item-content>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="tertiary" icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("buttons.close") }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-timeline dense>
          <v-timeline-item
            class="mb-1"
            :fill-dot="true"
            :color="item.color"
            :icon="item.icon"
            icon-color="white"
            v-for="(item, index) in data"
            :key="index"
          >
            <v-row class="align-center align-content-center justify-space-between">
              <v-col cols="3">
                <v-chip small class="ml-0" :color="item.color" disabled text-color="white">
                  {{ item.label }}
                </v-chip>
              </v-col>
              <v-col cols="9" class="text-right">
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="body-2">
                  {{ item.message }}
                </div>
                <div class="caption">{{ item.subMessage }}</div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "event-detail",
    components: {},
    data: () => ({
      dialog: false,
      date: null,
      data: [],
      dataType: null
    }),
    methods: {},
    created() {
      this.$eventBus.$on("calendar:detail", event => {
        if (event) {
          this.dialog = true;
          this.date = event.date;
          this.data = event.data;
          this.dataType = event.dataType;
        }
      });
    }
  };
</script>

<style scoped>
  .v-dialog__content {
    z-index: 9000 !important;
  }
</style>
